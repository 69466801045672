import { FiltersFetchInterface, FiltersValueType, GetFilterOptionsType } from '@just-ai/analytic-front';
import { FilterKey as FilterKeyAnalitic } from '@just-ai/analytic-front/dist/Reporter/api/client';
import { AuditFilter, FilterKey, ProductType } from '@just-ai/api/dist/generated/Audit';
import AccountEventService from '@just-ai/api/dist/services/AuditService';

import { userParamsForRequestLSKey } from './';

export const FILTER_KEYS = {};

export const takeValueFromLocalStorage = () => {
  const textObject = localStorage.getItem(userParamsForRequestLSKey);
  return textObject ? JSON.parse(textObject) : {};
};

export default class UserLogFiltersFetchInterface extends FiltersFetchInterface {
  filtersService: AccountEventService;
  accountId: number;
  language: string;
  products: ProductType[];
  productsChanged: boolean;

  constructor(filtersService: AccountEventService, accountId: number, language: string, products: ProductType[]) {
    super();
    this.filtersService = filtersService;
    this.accountId = accountId;
    this.language = language;
    this.products = products;
    this.productsChanged = false;
  }

  getFilters = async (props?: FiltersValueType) => {
    const productsFromFilter = props?.key === 'PRODUCT' ? props?.options : [];
    this.productsChanged = props?.key === 'PRODUCT' ? true : false;

    const filters = await this.filtersService.getAvailableFilters(
      this.accountId,
      this.language,
      this.productsChanged ? (productsFromFilter as ProductType[]) : this.products
    );
    const withAddedFlags = filters.map(filter => {
      if (filter.key === 'PRODUCT') {
        return {
          ...filter,
          settings: {
            ...filter.settings,
            updateFiltersOnChange: true,
          },
        };
      }
      return filter;
    });
    return { data: withAddedFlags };
  };

  getFiltersOptions = (key: FilterKeyAnalitic, _: string, options: GetFilterOptionsType = {}) => {
    const filterKey = key as unknown as FilterKey;
    return this.filtersService.getFilterOptions(this.accountId, filterKey, this.language, 0, 50, {
      filters: (options.filters as unknown as AuditFilter[]) || [],
    });
  };
}
